@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "roboto-reg";
    font-weight: 400;
    src: url("fonts/roboto/Roboto-Black.ttf") format("opentype");
  }
  @font-face {
    font-family: "roboto-bold";
    font-weight: 700;
    src: url("fonts/roboto/Roboto-Bold.ttf") format("opentype");
  }

  h1 {
    font-size: 1.5rem;
  }
}

/* format('opentype'); */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
